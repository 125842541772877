import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Product from "./components/Product";
import Routes from "./Routes/Route";
import ServiceComponentDetail from "./components/Service/SoftwareComponentDetail";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/">
          <Helmet>
            <title>Home - Rcndc</title>
            <link rel="canonical" href="https://rcndc.com" />
            <meta property="og:title" content="Home - Rcndc" />
            <meta property="article:section" content="Rcndc" />
            <meta property="og:url" content="https://rcndc.com" />
            <meta
              name="keywords"
              content="rcndc,infoethiopia,kushlivestock,alga"
            />
          </Helmet>
          <Routes />
        </Route>
        <div className="APP2">
          <Route exact path="/service/:serviceName">
            <ServiceComponentDetail />
          </Route>

          <Route exact path="/products">
            <Helmet>
              <title>Products - Rcndc</title>
              <link rel="canonical" href="https://rcndc.com" />
              <meta property="og:title" content="Products - Rcndc" />
              <meta property="article:section" content="Products" />
              <meta property="og:url" content="https://rcndc.com/products" />
              <meta name="keywords" content="rcndc,products" />
            </Helmet>
            <Product />
          </Route>
        </div>
      </Router>
    </div>
  );
}

export default App;
