import React from "react";
import { getAllServices } from "../actions/service";
import HomeBack from "../image/homeBACK.png";
import ServiceComponent from "./Service/ServiceComponent";
import ClientSlider from "./Service/SliderComponent";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
 // eslint-disable-next-line no-sparse-arrays
 const clients = [
   {
     name: "251 Communication",
     image: require("../image/client/251logo.png").default,
   },
   {
     name: "Adimera",
     image: require("../image/client/adimera.png").default,
   },
   ,
   {
     name: "Alga",
     image: require("../image/client/Alga-logo.png").default,
   },
   {
     name: "Batelian",
     image: require("../image/client/batelian logo.JPG").default,
   },
   {
     name: "Purpose Black",
     image: require("../image/client/black economy execllence logo.jpg")
       .default,
   },
   {
     name: "Gazetta",
     image: require("../image/client/gazettaa logo.png").default,
   },
   {
     name: "Info Ethiopia",
     image: require("../image/client/infoEthiopiaLogo.png").default,
   },
   {
     name: "Misso Bodywork",
     image: require("../image/client/misobody works logo.png").default,
   },
   {
     name: "Link Addis",
     image: require("../image/client/linkaddis logo (2).png").default,
   },
   {
     name: "Yemen Embassy",
     image: require("../image/client/yemen_embassy.png").default,
   },
   {
     name: "Souven Entertainment",
     image: require("../image/client/souvenenter logo.jpg").default,
   },
 ];
 const partners = [
   {
     name: "Evernex",
     image: require("../image/partner/evernex.png").default,
   },
   {
     name: "Voltec",
     image: require("../image/partner/Voltec.jpeg").default,
   },
   ,
   {
     name: "Google",
     image: require("../image/partner/Google_ G _logo.png").default,
   },
   {
     name: "Elevate",
     image: require("../image/partner/elevate.jpeg").default,
   },
   {
     name: "Microsoft 365",
     image: require("../image/partner/Microsoft_365.png").default,
   },
   {
     name: "IIDE Digital Marketing",
     image: require("../image/partner/IIDE.jpeg").default,
   },
   {
     name: "Cisco",
     image: require("../image/partner/cisco.jpg").default,
   },
   {
     name: "Huawei",
     image: require("../image/partner/huawei.jpeg").default,
   },
   {
     name: "AWS",
     image: require("../image/partner/aws_logo.png").default,
   },
   {
     name: "Commando Networks",
     image: require("../image/partner/CMD_Red.jpg").default,
   },
 ];
const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 668,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
    const imageStyle = {
      width: "200px", // Set your desired width here
      height: "auto", // Maintain aspect ratio by setting height to auto
      // You can adjust the width and height values as needed
    };
const Service = () => {
  const [services, setServices] = React.useState([]);
 
  React.useEffect(() => {
    getAllServices().then((data) => {
      if (data.err) {
        console.log(data.err);
      } else {
        setServices(data.result);
      }
    });
  }, []);
  return (
    <>
      <div>
        <div>
          <ClientSlider clients={clients} our="CLIENTS" />
        </div>
        <div>
          <ClientSlider clients={partners} our="PARTNERS" />
        </div>

        <div
          style={{
            backgroundImage: `url(${HomeBack})`,
            border: "1px solid transparent",
          }}
        >
          <div
            className="ourservice"
            style={{
              // paddingBottom: "30px",
              paddingTop: "30px",
              textAlign: "center",
            }}
          >
            <h1>OUR SERVICES</h1>
          </div>
          <div>
            {services.map((service, index) => {
              return <ServiceComponent serviceDetails={service} key={index} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
