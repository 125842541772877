import React from "react";
import { CCol, CRow, CContainer } from "@coreui/react";
import { Image } from "react-bootstrap";

import HomeImage from "../image/homeIMGC.png";

const Home = () => {
  return (
    <CContainer>
      <div className="home">
        <div className="container">
          <CRow>
            <CCol lg="5" sm="12" md="6" style={{ marginTop: "60px" }}>
              <div style={{ textAlign: "center" }}>
                <h1>GROW YOUR BUSINESS</h1>
              </div>
              <p style={{ marginTop: "20px" }}>
                RCNDC consultancy is a leading IT consultancy, computer
                networking and software solution provider. We have more than
                eight years of experience in serving clients worldwide. We can
                design and develop network systems and software solutions that
                suit your needs and goals. We can also help you with digital
                marketing strategies such as SEO and ASO, to increase your
                online visibility and reach.
              </p>
            </CCol>
 
            <CCol lg="7" sm="10" md="6">
              <Image src={HomeImage} fluid style={{ paddingTop: "20px" }} />
            </CCol>
            <br/>
            <br/>
          </CRow>
          <br/>
              <p>
                We have delivered innovative and reliable technology solutions
                for various sectors. We have worked with some of the top
                technology companies in the world, such as Cisco, Huawei,
                Google, IIDE and Microsoft. We are always ready to take on new
                challenges and provide you with the best IT solution possible.
                We are not just an IT consultancy company; we are your trusted
                partner in achieving your business objectives and maximizing
                your potential. Contact us today to find out how we can help you
                grow your IT needs.
              </p>
        </div>
      </div>
    </CContainer>
  );
};

export default Home;
