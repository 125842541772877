import React from "react";

const Footer = () => {
  return (
    <div
      className="footer"
      style={{
        backgroundColor: "rgb(0, 69, 132)",
        color: "white",
        textAlign: "center",
      }}
    >
      <label
        style={{
          textAlign: "center",
          width: "100%",
          margin: "auto",
        }}
      >
        {new Date().getFullYear()} RCNDC. All rights reserved.
      </label>
    </div>
  );
};

export default Footer;
