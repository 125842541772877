import fetch from "isomorphic-fetch";
import { API } from "../components/config";
export const getAllProducts = () => {
  return fetch(`${API}/api/get-all-products`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => err);
};
