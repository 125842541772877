import fetch from "isomorphic-fetch";
import { API } from "../components/config";
export const getAllServices = () => {
  return fetch(`${API}/api/get-all-services`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => err);
};
export const getAllServiceProducts = (Id) => {
  return fetch(`${API}/api/get-all-service-products-by-Id/${Id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => err);
};
