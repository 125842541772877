import React, { useEffect, useState } from "react";
import { RiArrowUpSLine } from "react-icons/ri";

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  return isVisible ? (
    <div onClick={scrollToTop} className="scroll-to-top">
      <RiArrowUpSLine />
    </div>
  ) : null;
}
