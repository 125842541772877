import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ClientSlider = ({ clients ,our }) => {
  const imageStyle = {
    width: "100px",
    height: "100px",
    // align: "center",
    borderRadius: "50%",
    margin:"0 10px"
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 3000, // Delay between slides in milliseconds
    // responsive: [
    //   {
    //     breakpoint: 368,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 380,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <div>
      <div
        className="ourservice"
        style={{
          paddingTop: "40px",
          paddingBottom: "40px",
          textAlign: "center",
        }}
      >
        <h3>OUR {our}</h3>
      </div>
      <div>
        <Slider {...settings}>
          {clients.map((client, index) => (
            <div key={index} style={{ margin: "0 -10px" , padding:"5px", "aspectRatio": 1.3/1,"maxWidth": "280px"}}>
              <img src={client.image} alt={client.name} style={imageStyle} />
            </div>
          ))}
        </Slider>
      </div>
      <br />
    </div>
  );
};

export default ClientSlider;
