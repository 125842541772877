import React from "react";
import { Image } from "react-bootstrap";
import { CCol, CRow, CContainer } from "@coreui/react";

import AboutImage from "../image/aboutIMAGEC.png";

const About = () => {
  return (
    <div className="section_content">
      <CContainer>
        <CRow>
          <CCol lg="6" sm="12" md="6" xl>
            <div>
              <h1
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingBottom: "10px",
                }}
              >
                ABOUT US
              </h1>
            <div style={{ color: "white" }} className="text-justify">
              We have extensive expertise in Networking, Hardware and Software
              Developments and mainly partner with international technology
              proveders.
              </div>
            {/* <ul style={{ listStyleType: "none", color: "white", padding: 0 }}>
              <li style={{ color: "white" }}>Cisco</li>
              <li style={{ color: "white" }}>Huawei</li>
              <li style={{ color: "white" }}>Google</li>
              <li style={{ color: "white" }}>IIDE</li>
              <li style={{ color: "white" }}>Microsoft</li>
            </ul> */}
            </div>
          </CCol>
          {/* <div style={{ color: "white" }} className="text-justify"> */}

          {/* </div> */}
          <CCol lg={6} sm={12} md={6} xl>
            {/* <div style={{ paddingBottom: "20px" }} className="text-justify">
              <ul style={{ listStyleType: "none", padding: 0, fontSize: 10 }}>
                <li style={{ color: "white" }}>Digital Marketing</li>
                <li style={{ color: "white" }}>Website Development</li>
                <li style={{ color: "white" }}>System Development</li>
                <li style={{ color: "white" }}>Office 365 Management</li>
                <li style={{ color: "white" }}>Media Monitoring</li>
                <li style={{ color: "white" }}>AD Design and Development</li>
                <li style={{ color: "white" }}>Call Center Development</li>
              </ul>
            </div> */}
          </CCol>

          <CCol lg="6" sm="12" md="6" xl style={{ paddingBottom: "20px" }}>
            <Image src={AboutImage} fluid />
          </CCol>
        </CRow>
      </CContainer>{" "}
      <div className="divider border-top "></div>
    </div>
  );
};

export default About;
