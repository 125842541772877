import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { CCol, CRow, CContainer } from "@coreui/react";
import { API } from "../config";
function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return str;
}
const ServiceComponent = ({ serviceDetails }) => {
  return (
    <CContainer>
      <div>
        <div
          className=""
          style={{
            paddingTop: "50px",
            marginBottom: "20px",
          }}
        >
          <CRow>
            <CCol lg="6" sm="12" md="6" xl className="my-3">
              <div style={{ textAlign: "center" }}>
                <h1 className="display-4 lead">
                  {serviceDetails.name.charAt(0).toUpperCase() +
                    serviceDetails.name.slice(1)}
                </h1>
              </div>
              <p className="lead">{serviceDetails.description}</p>
              <Link
                to={{
                  pathname: `/service/${slugify(serviceDetails.name)}`,
                  state: serviceDetails,
                }}
              >
                {" "}
                <div style={{ textAlign: "center" }}>
                  <button>Read more</button>
                </div>
              </Link>
            </CCol>

            <CCol lg="6" sm="12" md="6" xl>
              <div style={{ textAlign: "center" }}>
                <Image
                  src={`${API}/${serviceDetails.iconURI}`}
                  fluid
                  alt={serviceDetails.iconURI}
                />
              </div>
            </CCol>
          </CRow>
        </div>{" "}
      </div>
    </CContainer>
  );
};

export default ServiceComponent;
