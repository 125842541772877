import React from "react";
import { CCol, CRow, CContainer, CSpinner, CButton } from "@coreui/react";

import emailjs from "emailjs-com";
import { FaFacebookF, FaTelegram, FaLinkedin } from "react-icons/fa";
const Contact = () => {
  const [values, setValues] = React.useState({
    user_email: "",
    user_name: "",
    message: "",
    sending: false,
    success: null,
  });
  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setValues({ ...values, [name]: value });
  };
  const sendEmail = (e) => {
    e.preventDefault();
    setValues({ ...values, sending: true });
    emailjs
      .sendForm(
        "service_9wqcnc9",
        "template_suhvak9",
        e.target,
        "user_iK7SeS6qaYdv2LhHeLDBl"
      )
      .then(
        (result) => {
          setValues({ ...values, success: true, sending: false });
          e.target.reset();
          setTimeout(() => {
            setValues({ ...values, success: null, sending: false });
          }, 2000);
        },
        (error) => {
          setValues({ ...values, success: false, sending: false });
          setTimeout(() => {
            setValues({ ...values, success: null, sending: false });
          }, 2000);
        }
      );
  };

  return (
    <div className="section_content">
      <CContainer>
        <CRow>
          <CCol lg="6" sm="12" md="6" xl>
            <div>
              <h1
                style={{
                  color: "white",
                  textAlign: "center",
                  paddingBottom: "10px",
                }}
              >
                CONTACT US
              </h1>
            </div>
            <div>
              If you have any question or feedback about our services, please
              feel free to contact us on rcndc.com You can reach us by email at
              <b> contact@rcndc.com</b> or by phone <b> +251 925 002 580</b> or
              <b> +251 943 141 717</b>. Ethiopia , Addis Ababa, Round Megenagna
              Kokeb Building or by filling out the form on our website. we are
              always happy to hear from you and to assist with your needs. Thank
              you for choosing rcndc.com as your reliable and trusted partner.
            </div>
            <div
              className="divider border-top "
              style={{ margin: "2rem auto" }}
            />
            <div className="contact__sections">
              <div
                style={{
                  paddingBottom: "30px",
                  paddingleft: "5px",
                }}
              >
                <div>
                  <a
                    href="mailto:contact@rcndc.com"
                    target="_blank"
                    style={{
                      color: "white",
                    }}
                  >
                    contact@rcndc.com
                  </a>
                </div>
                <div>+251925002580/+251943141717</div>
                <div>P.O.Box 230/1070</div>
                <div>Addis Ababa,Ethiopia</div>
              </div>
              <div className="social-media">
                <a
                  target="_blank"
                  href="https://et.linkedin.com/company/rcndc"
                  style={{ color: "white" }}
                >
                  <FaLinkedin className="space" size={30} />
                </a>
                <a
                  target="_blank"
                  href="https://t.me/RCNDC"
                  style={{ color: "white" }}
                >
                  <FaTelegram className="space" size={30} />
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/RCNDC-108409531316621"
                  style={{ color: "white" }}
                >
                  <FaFacebookF className="space" size={30} />
                </a>
              </div>
            </div>
          </CCol>

          <CCol lg="6" sm="12" md="6" xl>
            <form onSubmit={sendEmail}>
              <input
                name="user_email"
                type="email"
                value={values.user_email}
                onChange={handleInputChange}
                required
                placeholder="Your email"
                style={{ marginTop: "30px" }}
                className="form-control"
              ></input>
              <input
                name="user_name"
                value={values.user_name}
                onChange={handleInputChange}
                required
                placeholder="Your name"
                style={{ marginTop: "30px" }}
                className="form-control"
              ></input>
              <textarea
                placeholder="write something"
                name="message"
                multiline={true}
                value={values.message}
                onChange={handleInputChange}
                required
                style={{ marginTop: "30px" }}
                className="form-control"
              ></textarea>

              <CButton
                color="success"
                className="my-4 "
                disabled={values.sending}
                type="submit"
              >
                {values.sending && (
                  <CSpinner component="span" size="sm" color="primary" />
                )}
                {values.success == null
                  ? "Submit"
                  : values.success
                  ? "Sent"
                  : "Failed, try again"}
              </CButton>
            </form>
          </CCol>
        </CRow>
      </CContainer>{" "}
    </div>
  );
};

export default Contact;
