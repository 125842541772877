import React from "react";
import { CContainer } from "@coreui/react";
import HomeBack from "../image/homeBACK.png";
import Footer from "./Footer";
import { API } from "./config";
import { getAllProducts } from "../actions/product";
import Topnav from "./topnav/TopNav";

const Product = () => {
  const [products, setProducts] = React.useState([]);

  React.useEffect(() => {
    getAllProducts().then((data) => {
      if (data.err) {
        console.log(data.err);
      } else {
        setProducts(data.result);
      }
    });
  }, []);

  return (
    <CContainer fluid>
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Topnav />
        <div
          className="child pt-5 px-2"
          style={{
            backgroundImage: `url(${HomeBack})`,
            flexGrow: "1",
            paddingBottom: "6rem",
          }}
        >
          <h1 style={{ textAlign: "center", paddingBottom: "20px" }}>
            OUR PRODUCTS
          </h1>

          <CContainer>
            <div className="product__list">
              {products.map((product, index) => {
                return (
                  <div className="wrapper">
                   
                      <div className="card">
                        <div
                          className="product_img"
                          style={{
                            backgroundImage:
                              "url(" +
                              API +
                              "/" +
                              product.ProductImages[0]?.imageURI +
                              ")",
                          }}
                        />
                        <div className="product_title">
                          {product.name.charAt(0).toUpperCase() +
                            product.name.slice(1)}
                        </div>
                        {/* <div className="product_description">
                          {product.description}
                        </div> */}
                        <div className="product_specs">
                          {product.ProductSpecifications.map((spec) => {
                            return (
                              <div className="spec">
                                <span></span>
                                {spec.detail}
                              </div>
                            );
                          })}
                        </div>
                        <div className="product_price">
                          <span style={{ fontSize: "1rem" }}>Birr</span>
                          {product.price.toFixed(2)}
                        </div>
                      </div>
                      <div className="product__drop__overlay">
                        <div
                          className="product_img"
                          style={{
                            backgroundImage:
                              "url(" +
                              API +
                              "/" +
                              product.ProductImages[0]?.imageURI +
                              ")",
                          }}
                        />
                        <div className="product_title">
                          {product.name.charAt(0).toUpperCase() +
                            product.name.slice(1)}
                        </div>
                        {/* <div className="product_description">
                          {product.description}
                        </div> */}
                        <div className="product_specs">
                          {product.ProductSpecifications.map((spec) => {
                            return (
                              <div className="spec">
                                <span></span>
                                {spec.detail}
                              </div>
                            );
                          })}
                        </div>
                        <div className="product_price">
                          <span style={{ fontSize: "1rem" }}>Birr</span>
                          {product.price.toFixed(2)}
                        </div>
                      </div>{" "}
                    </div>
                 
                );
              })}
            </div>
          </CContainer>
        </div>
        <Footer />
      </div>
    </CContainer>
  );
};

export default Product;
