import React from "react";

import { Image } from "react-bootstrap";
import RCNDCW from "../image/RCNDC-logo.png";
import Home from "../components/Home";
import About from "../components/About";
import Contact from "../components/Contacts";
import Service from "../components/Service";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import ScrollToTop from "../components/scrolltotop";
import { BiCartAlt } from "react-icons/bi";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { IconButton } from "@mui/material";
import { AiOutlineMenu } from "react-icons/ai";
import { useHistory } from "react-router-dom";
const Routes = () => {
  const history = useHistory();
  const homeSection = React.createRef();
  const aboutSection = React.createRef();
  const serviceSection = React.createRef();
  const contactSection = React.createRef();
  const [state, setState] = React.useState({
    left: false,
  });
  React.useEffect(() => {
    let value = history.location.state;

    if (value) {
      if (value === "home") {
        scrollTo(homeSection);
      }
      if (value === "service") {
        scrollTo(serviceSection);
      }
      if (value === "about") {
        scrollTo(aboutSection);
      }
      if (value === "contact") {
        scrollTo(contactSection);
      }
    }
    return () => {
      value = null;
    };
  }, [
    contactSection,
    aboutSection,
    serviceSection,
    homeSection,
    history.location.state,
  ]);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      window.scrollTo(0, ref.current.offsetTop);
      // ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  window.addEventListener("scroll", () => {
    const element = document.getElementsByClassName("topnav")[0];
    if (element) {
      if (window.scrollY > 20) {
        element.style.backgroundColor = "white";
        element.style.padding = "10px 30px";
      } else {
        element.style.backgroundColor = "transparent";
        element.style.padding = "15px 30px";
      }
    }
  });
  const Lists = () => {
    return (
      <ul className="topnav__right ">
        <ListItem
          button
          component={Button}
          onClick={() => scrollTo(homeSection)}
        >
          <ListItemText primary="HOME" />
        </ListItem>

        <ListItem
          button
          component={Button}
          onClick={() => scrollTo(serviceSection)}
        >
          <ListItemText primary="SERVICE" />
        </ListItem>
        {/* <ListItem
          button
          component={Button}
          onClick={() => scrollTo(aboutSection)}
        >
          <ListItemText primary="ABOUT" />
        </ListItem> */}
        <ListItem
          button
          component={Button}
          onClick={() => scrollTo(contactSection)}
        >
          <ListItemText primary="CONTACT" />
        </ListItem>
        <ListItem button component={Link} to="/products">
          <ListItemText primary="PRODUCTS" />
          <ListItemIcon>
            <BiCartAlt />
          </ListItemIcon>
        </ListItem>
      </ul>
    );
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className="sidebar__logo"
        onClick={() => {
          history.push("/");
        }}
      >
        <Image
          src={RCNDCW}
          fluid
          className="ImageLogo"
          style={{ width: "80px", height: "80px" }}
        />
      </div>
      <Lists />
    </Box>
  );
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: "1" }}>
        <div className="topnav">
          <div className="topbar">
            <div className="topnav__MenuIcon">
              <Link to="/">
                <div className="topnav__logo">
                  <Image src={RCNDCW} fluid />
                </div>
              </Link>
            </div>
            <Lists />
          </div>
        </div>
        <div>
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <div
                className="sidebar__toggler"
                onClick={toggleDrawer(anchor, true)}
              >
                <IconButton color="inherit" aria-label="open drawer">
                  {" "}
                  <AiOutlineMenu />
                </IconButton>
              </div>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
        <div className="APP2" style={{ overFlow: "auto" }}>
          <ScrollToTop />
          <div ref={homeSection}>
            <Home />
          </div>
          <div ref={serviceSection}>
            <Service />
          </div>
          <div className="sections">
            <div ref={aboutSection}>
              {/* <About /> */}
            </div>
            <div ref={contactSection}>
              <Contact />
            </div>{" "}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Routes;
