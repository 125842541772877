import React from "react";
import "./topnav.css";
import { BiCartAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import RCNDCW from "../../image/RCNDC-logo.png";
import { Image } from "react-bootstrap";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { IconButton } from "@mui/material";
import { AiOutlineMenu } from "react-icons/ai";
import { useHistory } from "react-router-dom";
const Topnav = () => {
  const history = useHistory();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  window.addEventListener("scroll", () => {
    const element = document.getElementsByClassName("topnav")[0];
    if (element) {
      if (window.scrollY > 20) {
        element.style.backgroundColor = "white";
        element.style.padding = "10px 30px";
      } else {
        element.style.backgroundColor = "transparent";
        element.style.padding = "15px 30px";
      }
    }
  });
  const Lists = () => {
    return (
      <ul className="topnav__right ">
        <ListItem button component={Link} to={{ pathname: "/", state: "home" }}>
          <ListItemText primary="HOME" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={{ pathname: "/", state: "service" }}
        >
          <ListItemText primary="SERVICE" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={{ pathname: "/", state: "about" }}
        >
          <ListItemText primary="ABOUT" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={{ pathname: "/", state: "contact" }}
        >
          <ListItemText primary="CONTACT" />
        </ListItem>
        <ListItem button component={Link} to="/products">
          <ListItemText primary="PRODUCTS" />
          <ListItemIcon>
            <BiCartAlt />
          </ListItemIcon>
        </ListItem>
      </ul>
    );
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        className="sidebar__logo"
        onClick={() => {
          history.push("/");
        }}
      >
        <Image
          src={RCNDCW}
          fluid
          className="ImageLogo"
          style={{ width: "80px", height: "80px" }}
        />
      </div>
      <Lists />
    </Box>
  );
  return (
    <>
      <div className="topnav">
        <div className="topbar">
          <div className="topnav__MenuIcon">
            <Link to="/">
              <div className="topnav__logo">
                <Image src={RCNDCW} fluid />
              </div>
            </Link>
          </div>
          <Lists />
        </div>
      </div>
      <div>
        {" "}
        {["left"].map((anchor) => (
          <React.Fragment key={anchor}>
            <div
              className="sidebar__toggler"
              onClick={toggleDrawer(anchor, true)}
            >
              <IconButton color="inherit" aria-label="open drawer">
                {" "}
                <AiOutlineMenu />
              </IconButton>
            </div>{" "}
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              {list(anchor)}
            </SwipeableDrawer>
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default Topnav;
