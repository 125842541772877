import React from "react";
import { Image } from "react-bootstrap";
import { CCol, CContainer, CRow } from "@coreui/react";
import HomeBack from "../../image/homeBACK.png";
import Footer from "../Footer";
import { useHistory } from "react-router-dom";
import { API } from "../config";
import { getAllServiceProducts } from "../../actions/service";
import Topnav from "../topnav/TopNav";
import { Helmet } from "react-helmet";
function slugify(str) {
  str = str.replace(/^\s+|\s+$/g, "");

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  var from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  // Remove invalid chars
  str = str
    .replace(/[^a-z0-9 -]/g, "")
    // Collapse whitespace and replace by -
    .replace(/\s+/g, "-")
    // Collapse dashes
    .replace(/-+/g, "-");

  return str;
}
const ServiceComponentDetail = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [serviceDetails, setServiceDetails] = React.useState(
    history.location.state
  );
  const [serviceProducts, setServiceProducts] = React.useState([]);
  React.useEffect(() => {
    setLoading(true);
    getAllServiceProducts(serviceDetails.Id).then((data) => {
      if (data.err) {
        setLoading(false);
      }
      if (data.result) {
        setServiceProducts(data.result);
        setLoading(false);
      }
    });
  }, [serviceDetails]);

  return (
    <div>
      <Helmet>
        <title>Service Detail - Rcndc</title>
        <link
          rel="canonical"
          href={`https://rcndc.com`}
        />
        <meta property="og:title" content="Service Detail - Rcndc" />
        <meta property="article:section" content="Service Detail" />
        <meta
          property="og:url"
          content={`https://rcndc.com/service/${slugify(serviceDetails.name)}`}
        />
        <meta name="keywords" content="rcndc,services" />
      </Helmet>
      <Topnav />
      <div className="child" style={{ backgroundImage: `url(${HomeBack})` }}>
        <CContainer>
          <CRow>
            <CCol sm="12" md={6}>
              <div>
                <h1 className="display-4 lead text-center">
                  {serviceDetails &&
                    serviceDetails?.name?.charAt(0).toUpperCase() +
                      serviceDetails?.name?.slice(1)}
                </h1>
              </div>
              <p className="lead">
                {serviceDetails && serviceDetails.description}
              </p>
            </CCol>

            <CCol sm="12" md={6}>
              <Image
                src={`${API}/${serviceDetails.imageURI}`}
                fluid
                alt={serviceDetails.imageURI}
              />
            </CCol>
          </CRow>

          <CRow>
            <div style={{ paddingTop: "100px" }}>
              <h1 style={{ textAlign: "center" }}>Our Products</h1>
            </div>
            <CCol lg="6" sm="12" md="6" xl></CCol>
          </CRow>
          <div className="d-flex align-items-center justify-content-center py-3">
            {loading ? (
              <div className="spinner-border  text-primary p-5" role="status" />
            ) : (
              <CRow>
                {serviceProducts.map((product) => {
                  return (
                    <CCol sm="6" md="3" className="p-2">
                      <a target="_blank" href={product.url}>
                        <div className="products__card rounded shadow">
                          <Image src={`${API}/${product.logoURI}`} fluid />
                        </div>
                      </a>
                    </CCol>
                  );
                })}
              </CRow>
            )}
          </div>
        </CContainer>
      </div>
      <Footer />{" "}
    </div>
  );
};

export default ServiceComponentDetail;
